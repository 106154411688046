let Title = props =>  {

  return (
    <div className="app-title">
        <h1>{ props.title }</h1>
    </div>
  );
}

export default Title;
