let MainText = props =>  {

    return (
      <div className="app-main-text">
          <p dangerouslySetInnerHTML={ { __html: props.text } }></p>
      </div>
    );
  }
  
export default MainText;
  