import { useState, useEffect } from 'react';
import CheckNumbers from './components/check-numbers/CheckNumbers';
import ChooseOption from './components/choose-option/ChooseOption';
import ChooseSofa from './components/choose-sofa/ChooseSofa';
import MainText from './components/text/Text';
import Title from './components/title/Title';
import { ReactComponent as InstagramIco } from './img/instagram-ico.svg';
import decorImage from './img/decor-image.png';
import './scss/main.scss';

function App() {
  let [ gasEmision, setGasEmision ] = useState(0);
  let [ gasMining, setGasMining ] = useState(0);
  let [ energyUsage, setEnergyUsage ] = useState(0);
  let [ demandWater, setDemandWater ] = useState(0);
  let [ sewageProduction, setSewageProduction ] = useState(0);
  let [ chemicals, setChemicals ] = useState(0);
  let [ isManualMode, setManualMode ] = useState(false);
  let [ choosedSofa, setChoosedSofa ] = useState(null);
  let [ manualMeters, setManualMeters ] = useState(null);
  let [ choosedMeters, setChoosedMeters ] = useState(null);
  let [ load, setLoad ] = useState(null);
  let [ windowWidth, setWindowWidth ] = useState(window.innerWidth);
  let [ translations, setTranslations ] = useState(null);


  let emisionMultipler    = 3.75;
  let gasMiningMultipler  = 1.40;
  let energyMultipler     = 48.31;
  let waterMulipler       = 57.04;
  let sewageMultipler     = 3.3;
  let chemicalsMulipler   = 97.2;

  useEffect(() => {
    if ( typeof( choosedMeters ) === 'number' ) {
      calculate();
    }
  }, [choosedMeters]) 

  useEffect(() => {
    if ( load ) {
      setGasEmision( 0 );
      setGasMining( 0 );
      setEnergyUsage( 0 );
      setDemandWater( 0 );
      setSewageProduction( 0 );
      setChemicals( 0 );
    }
    
  }, [isManualMode]);

  useEffect(() => {
    if ( !load ) {
      getTranslates();
      setLoad(true);
    }
  }) 

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    }
  })

  let getCookie = cookieName => {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      if( cookies[i].indexOf('=') > -1) {
        var name = cookies[i].split('=')[0].toLowerCase().trim();
        var value = cookies[i].split('=')[1].toLowerCase();
        if (name === cookieName) {
          return value;
        } else if (value === cookieName) {
          return name;
        }
      }
    }
    return false;
  };

  let getTranslates = async() => {
    let getLang = getCookie('lang');

    let url = `https://api.jsonbin.io/b/6061a82eb0a45d7e6027eba6`;
    // let url = `https://upcycledfabrics.eu/lang/calc-${ getLang ? getLang : 'pl' }.json`;
    
    let obj = await (await fetch(url)).json();
    setTranslations(obj);
  }
 
  let calculate = () => {
    setGasEmision( choosedMeters * emisionMultipler );
    setGasMining( choosedMeters * gasMiningMultipler );
    setEnergyUsage( choosedMeters * energyMultipler );
    setDemandWater( choosedMeters * waterMulipler );
    setSewageProduction( choosedMeters * sewageMultipler );
    setChemicals( choosedMeters * chemicalsMulipler );
  }

  let setChooseOption = i => {
    if ( i === 0 ) {
      setManualMode( true )
    } else {
      setManualMode( false )
      setManualMeters('');
      setChoosedSofa(null);

    }
  }

  let handleInput = val => {
    if (val.length > 0) {
      let allowChars = /^[0-9]{0,10}$/;
      let regExp = new RegExp(allowChars);

      if ( (!manualMeters || manualMeters === 0) && val === '0') return false;
      if(regExp.test(val)) {
        setManualMeters(val);
        setChoosedMeters(parseFloat(val));
      } else {
        return false;
      }
    } 
    if ( val <= 0 && val >= 10000000 ) {
      return false
    }
    setManualMeters(val);
    setChoosedMeters(parseFloat(val));
  }

  return (
    <div className="upcycled-app">
      { load && translations &&
        <div className="container">
          <div className="col">
            <Title title={ translations.main_hedaer } />
            <MainText text={ translations.main_description } />
            <ChooseOption setFunction={ ( i ) => setChooseOption( i ) } 
                          textLeft={ translations.btn_choose_left } 
                          textRight={ translations.btn_choose_right } />
            { !isManualMode &&
              <MainText text={ translations.choose_right_text } />
            }
            { isManualMode &&
              <>
                <MainText text={ translations.choose_left_text } />
                <div className="input">
                  <input  type="text" 
                          value={ manualMeters } 
                          onChange={ (e) => handleInput(e.target.value) }  />
                </div>
              </>
            }
            { !isManualMode && windowWidth < 1210 &&
              <ChooseSofa translations={ translations } smallDevice value={ choosedSofa } setFunction={ ( sofa, val ) => { setChoosedSofa(sofa); setChoosedMeters( val ) } } isManualMode={ isManualMode } />
            }
            <CheckNumbers gas={ !isNaN(gasEmision) ?  gasEmision : 0 } 
                          mining={ !isNaN(gasMining) ? gasMining : 0 } 
                          energy={ !isNaN(energyUsage) ? energyUsage : 0 }
                          water={ !isNaN(demandWater) ? demandWater : 0 }
                          sewage={ !isNaN(sewageProduction) ? sewageProduction : 0 }
                          chemicals={ !isNaN(chemicals) ? chemicals : 0 } 
                          translations={ translations } 
                          manualMeters={ manualMeters } />
            
            <div className="app-footer">
              <div className="button-raport">
                <a className="btn" href={ translations.more_link } target="_blank">
                  { translations.btn_black_text }
                </a>
              </div>
              <a className="social" href={ translations.social_link } target="_blank" >
                { translations.social_text }
                <InstagramIco />
              </a>
            </div>
          </div>
          <div className="col">
            { !isManualMode && windowWidth > 1210 &&
              <ChooseSofa translations={ translations } setFunction={ ( sofa, val ) => { setChoosedSofa(sofa); setChoosedMeters( val ) } } value={ choosedSofa } isManualMode={ isManualMode } />
            }
            { isManualMode && 
              <div className="decor">
                <img src={ decorImage } alt="upcycled fabrics"/>
              </div>
            }
          </div>
        </div>
      }
      
    </div>
  );
}

export default App;
