import { useState, useEffect } from 'react';
import sofaSmall from './../../img/sofa-1.png';
import sofaMedium from './../../img/sofa-2.png';
import sofaBig from './../../img/sofa-3.png';


let ChooseSofa = props =>  {
    let [ activeIndex, setActiveIndex ] = useState(-1);
    let [ load, setLoad ] = useState( false );


    let arraySofa = [
        {
            name: props.translations.sofa_two,
            meters: 8,
            image: sofaSmall
        },
        {
            name: props.translations.sofa_three,
            meters: 11,
            image: sofaMedium
        },
        {
            name: props.translations.sofa_multi,
            meters: 14,
            image: sofaBig
        },
    ]

    useEffect(() => {
        if ( !load ) {
            setLoad(true)
        }
    }, [load]);

    useEffect(() => {
        if ( props.value && activeIndex === -1) {
            setActiveIndex(props.value);
        }
    }, [ props.value ]);

    useEffect(() => {
        if ( load ) {
            setActiveIndex(-1);
        }
    }, [props.isManualMode])

    let setSofa = ( i, meters ) => {
        props.setFunction( i, meters );
        setActiveIndex(i);

        if ( props.smallDevice ) {
            let element = document.getElementById('results');
            let topElement = element.getBoundingClientRect().top;
            let windowScroll = window.scrollY;
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: windowScroll + topElement
              });
        }
    }

    return (
      <div className={"app-choose-sofa" + (props.smallDevice ? ' small' : '')}>
          { arraySofa.map( ( el, i ) => (

            <div className={"sofa" + (activeIndex === i ? ' active' : '')} key={ i } onClick={ () => setSofa( i, el.meters ) }>
                <div className="info">
                        <div className="info-wrap">
                            <h3 dangerouslySetInnerHTML={ { __html: el.name } } />
                            <p>{ el.meters }m<sup>2</sup></p>
                        </div>
                </div>
                <div className="render">
                    <img src={ el.image } alt="" />
                </div>
            </div>

          ))}
          
      </div>
    );
  }
  
export default ChooseSofa;
  