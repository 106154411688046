import { useState } from 'react';

let ChooseOption = props =>  {
    let [ activeIndex, setActiveIndex ] = useState(1);

    let setOption = i => {
        props.setFunction(i);
        setActiveIndex(i)
    }
    return (
      <div className="app-choose-option">
          <div className={"active" + ( activeIndex === 1 ? ' right' : '')} />
          <div className="choose-btn" onClick={ () => setOption(0) }>
            { props.textLeft }
          </div>
          <div className="choose-btn" onClick={ () => setOption(1) }>
            { props.textRight  }
          </div>
      </div>
    );
  }
  
export default ChooseOption;
  